import React from 'react';
import ImageGallery from '../components/ImageGallery'
import LogoGallery from '../components/LogoGallery';
import VideoGallery from '../components/VideoGallery';
import { motion } from 'framer-motion';

const pageVariants = {
  initial: { opacity: 0, x: '-100vw' },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: '100vw' },
};


const DesignPortfolio = () => {

  return (
    <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}  >
       <div className='mx-auto justify-center lg:w-10/12'>
       <section className='min-h-fit mb-8 px-5 justify-self-center xl: flex flex-wrap flex-row-reverse justify-start'>

        <VideoGallery />

        </section>
        <section className='min-h-fit mb-8 px-5 justify-self-center xl: flex flex-wrap flex-row-reverse justify-start'>

          <ImageGallery />

        </section>

        <section className='min-h-fit mb-8 px-5 justify-self-center xl: flex flex-wrap flex-row-reverse justify-start'>

          <LogoGallery />

        </section>
      </div>
  </motion.div>
  );
};

export default DesignPortfolio;
