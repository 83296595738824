import React from 'react';
import Projects from '../components/Projects'
import CaseStudiesComponent from '../components/CaseStudies';
import { motion } from 'framer-motion';


const pageVariants = {
  initial: { opacity: 0, x: '-100vw' },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: '100vw' },
};

const DevPortfolio = () => {

  return (
    <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}  >
       <div className='mx-auto justify-center lg:w-10/12'>
       <section className='min-h-fit mb-8 px-5 justify-self-center'>
          <div className='p-5 mt-5'>
            <div className='pb-0'>
              <h3 className='text-slate-950 text-3xl font-bold pt-1 md:text-5xl dark:text-slate-400'>Coding Portfolio</h3>
              <p className='text-left text-md my-5 pt-5 sm:leading-8 text-slate-950 md:leading-8 md:text-2xl dark:text-slate-400'>These are case studies written about projects I've developed through freelance work, my career, and at university.</p>
            </div>
          </div>
          <CaseStudiesComponent></CaseStudiesComponent>

          <Projects></Projects>

          </section>
    </div>
    </motion.div>

  );
};

export default DevPortfolio;
