import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTimes, FaArrowLeft, FaArrowRight } from 'react-icons/fa';

// const importAll = (requireContext) => requireContext.keys().map(requireContext);
// const images = importAll(require.context('../media/Artwork/', false, /\.(png|jpe?g|svg)$/));
// public/Artwork/Balance-Transfer-Social-Ads.png
const imgs = [
{title:'Vehicle Wrap', alt:'Vehicle Wrap', src: '../Artwork/Mobile-Branch-Wrap-Design.png', desc: 'Custom designed vehicle wrap. One of the more challenging projects I\'ve worked on.'},
{title:'Email Header', alt:'Email Header', src: '../Artwork/HELOC-Promo-Email-Header.jpg', desc: 'Graphics created for a lending campaign featuring original 3D models I designed.'},
{title:'Flyer', alt:'Flyer', src: '../Artwork/Debit-Promo-Flier.jpg', desc: 'Original design for a Visa Debit Card awareness campaign featuring cards I designed as well.'},
{title:'Google Display Ads', alt:'Google Display Ads', src: '../Artwork/Debit-Promo-Google-Ads.png', desc: 'Original design for a Visa Debit Card awareness campaign featuring cards I designed as well.'},
{title:'Social Ads', alt:'Social Ads', src: '../Artwork/Debit-Promo-Social-Ads.png', desc: 'Original design for a Visa Debit Card awareness campaign featuring cards I designed as well.'},
{title:'Flyer', alt:'Flyer', src: '../Artwork/Story-Time-Event-Collateral.png', desc: 'Custom flyer design for a one-off event.'},
{title:'Activity Sheet', alt:'Activity Sheet', src: '../Artwork/Team-Pelican-Colorsheet.png', desc: 'Custom activity sheet featuring a character I designed.'},
{title:'Postcard', alt:'Postcard', src: '../Artwork/Team-Pelican-Postcard.png', desc: 'Postcard designed for a youth product awareness campaign.'},
{title:'Die Cut Sticker', alt:'Die Cut Sticker', src: '../Artwork/Future-Millionaire-Sticker.png', desc: 'Die cut sticker created for a youth product awareness campaign.'},
{title:'Google Display Ads', alt:'Google Display Ads', src: '../Artwork/Balance-Transfer-Google-Ads.png', desc: 'Original design for a balance transfer campaign featuring a Visa Credit Card I designed as well.'},
{title:'Social Ads', alt:'Social Ads', src: '../Artwork/Balance-Transfer-Social-Ads.png', desc: 'Original design for a balance transfer campaign featuring a Visa Credit Card I designed as well.'},
{title:'Poster', alt: 'Poster', src: '../Artwork/Financial-Wellness-Poster.jpg', desc: 'Poster designed while rebranding the company\'s Financial Wellness program.'},
{title:'Flyer', alt:'Flyer', src: '../Artwork/Financial-Wellness-Flier.png', desc: 'Flyer designed while rebranding the company\'s Financial Wellness program.'},
{title:'PowerPoint Template', alt:'PowerPoint Template', src: '../Artwork/Fiancial-Wellness-PowerPoint.png', desc: 'PowerPoint slides I designed from a project rebranding the company\'s Financial Wellness program.'},
{title:'Custom Email', alt:'Custom Email', src: '../Artwork/Financial-Wellness-Email-Lightmode.jpg', desc: 'Custom email templates I designed while rebranding the company\'s Financial Wellness program.'},
{title:'Social Ads', alt:'Social Ads', src: '../Artwork/Financial-Wellness-Instagram-Posts.png', desc: 'Social media event graphics I designed while rebranding the company\'s Financial Wellness program.'},
{title:'Activity Sheet', alt:'Activity Sheet', src: '../Artwork/Financial-Wellness-Worksheet.jpg', desc: 'Activity sheet designed while rebranding the company\'s Financial Wellness program.'},
{title:'Temporary Tattoo', alt:'Temporary Tattoo', src: '../Artwork/Petey-Mascot-Tattoo.png', desc: 'I was tasked with designing a fun temporary tattoo featuring the brand mascot.'},
// {title:'Character Design', alt:'Character Design', src: '../Artwork/Petey-Pelican-Character-Illustration.png', desc: ''},
{title:'Retractable Banner', alt:'Retractable Banner', src: '../Artwork/Rectractable-Banner.jpg', desc: 'Custom retractable banner designed to work within the team\'s event displays.'},
// {title:'Web Banner', alt:'Web Banner', src: '../Artwork/Share-Certificate-Banner.jpg', desc: ''},
// {title:'Billboard', alt:'Billboard', src: '../Artwork/Youth-Account-Billboards.png', desc: ''},
{title:'Package Design', alt:'Package Design', src: '../Artwork/Petey-Pals-Box.jpg', desc: 'Custom package design for youth account gifts. This project was never realized, but it was a fun concept.'},

];

const ImageGallery = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const closeModal = () => setSelectedIndex(null);

  const nextImage = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % imgs.length);
  };

  const prevImage = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex === 0 ? imgs.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="p-5 mt-5">
      <div className=''>
        <h3 className="text-slate-950 text-3xl font-bold py-1 md:text-4xl dark:text-slate-400">
          Creative Campaigns
        </h3>
        <p className="text-left text-md my-5 py-1 sm:leading-8 text-slate-950 md:leading-8 md:text-xl dark:text-slate-400">
          For over 17 years I have worked on many design projects spanning print media, digital media,
          vehicle wraps, Visa Debit and Credit Cards, and all the way to temporary tattoos. This is a gallery of the
          most recent creative campaigns and projects I've designed.
        </p>
      </div>
      <div className="flex flex-1 flex-col gap-10 sm:flex-row flex-wrap">
        {imgs.map((image, index) => {
          // const capText = imgs.split('/').pop().split('.')[0].replace(/-/g, ' ');

          return (
            <div
              key={index}
              className="three-col rounded-lg p-6 border-0 border-slate-50 bg-slate-50 hover:bg-white hover:dark:bg-slate-800 dark:bg-slate-900 cursor-pointer"
              onClick={() => setSelectedIndex(index)}
            >
              <div className="flex flex-wrap flex-col h-auto items-center justify-center">
              <img
                  src={image.src}
                  alt={`Portfolio Item ${image.alt}`}
                  className="rounded-sm overflow-hidden w-full h-auto object-cover"
                />
                <h2 className="w-full pt-4 font-bold text-xl text-slate-950 dark:text-white">
                  {image.title}
                </h2>
                <p className='w-full pb-10 text-sm text-slate-950 dark:text-white'>{image.desc}</p>

              </div>
            </div>
          );
        })}
      </div>

      {/* Modal */}
      <AnimatePresence>
        {selectedIndex !== null && (
          <>
            {/* Overlay */}
            <motion.div
              className="fixed inset-0 bg-black bg-opacity-50 z-[1000]" // High z-index
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={closeModal}
            />

            {/* Modal Content */}
            <motion.div
              className="fixed inset-0 flex items-center justify-center z-[1100]" // Higher z-index than the overlay
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}

            >
              <div className="bg-white dark:bg-slate-900 rounded-lg max-w-3xl w-full shadow-lg h-[90vh] flex flex-col items-center justify-center relative">
                {/* Close Button */}
                <button
                  className="absolute top-4 right-4 text-slate-950 dark:text-slate-400 text-xl"
                  onClick={closeModal}
                >
                  <FaTimes />
                </button>

                {/* Navigation Arrows */}
                <button
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 text-slate-950 dark:text-slate-400 text-2xl"
                  onClick={prevImage}
                >
                  <FaArrowLeft />
                </button>
                <button
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 text-slate-950 dark:text-slate-400 text-2xl"
                  onClick={nextImage}
                >
                  <FaArrowRight />
                </button>

                {/* Image and Caption */}
                <img
                  src={imgs[selectedIndex].src}
                  alt={`Promo for ${selectedIndex + 1}`}
                  className="p-6 mt-10 justify-self-center rounded-lg overflow-hidden w-auto h-auto max-h-[80vh]"
                  onClick={nextImage}
                />
                <p className="text-center mt-2 mb-6 text-slate-950 dark:text-slate-400">
                  {imgs[selectedIndex].title}
                </p>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ImageGallery;
