import React, { useState } from 'react';
import { useTheme } from '../ThemeContext';
import { BsFillMoonStarsFill, BsFillSunFill } from 'react-icons/bs'
import { NavLink } from 'react-router'
import styled from 'styled-components'
import { FaBars, FaTimes } from 'react-icons/fa'


const Bars = styled.div`
  z-index: 1000;
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: ${props => (props.darkMode ? '#fff' : '#000')};
  @media (max-width: 768px) {
    display: block;
  }
`;

const NavBar = () => {

    const { darkMode, toggleDarkMode } = useTheme();

    // const [darkMode, setdarkMode] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);
    
    const menuHandler = () => {
      setMenuOpen(!menuOpen);
    };

    const linkExit = () => {
        setMenuOpen(false);
        window.scrollTo({top:0,behavior:'smooth'});
      };

  return (
    <>
    <nav className={`z-[10] w-full p-5 mb-12 max-sm:mb-0 flex flex-row justify-between gap-0 top-0 sticky ${darkMode ? 'dark bg-slate-950 navvy-shad-none' : 'bg-slate-50 navvy-shad'}`}>
            <h1 className='
            
            
            flex justify-start align-middle text-slate-900 text-md md:text-xl md:ml-4 lg:text-xl font-semibold my-auto dark:text-slate-50 transition-all duration-300 transition-width'>
            aaroncwilliams 
            { darkMode ? 
            <BsFillSunFill onClick={() => toggleDarkMode(!darkMode)} className='text-slate-50 ml-2 cursor-pointer align-middle translate-y-1'/> 
            : 
            <BsFillMoonStarsFill onClick={() => toggleDarkMode(!darkMode)} className='text-slate-900 ml-2 cursor-pointer align-middle translate-y-1'/> 
            }
            </h1>

            <Bars darkMode={darkMode} onClick={menuHandler}>
            {menuOpen ? <FaTimes /> : <FaBars />}
            </Bars>

            <ul className={`mobyMenu absolute top-0 left-0 w-full min-h-svh p-10 text-2xl text-center md:flex-row items-center transition-all duration-300 ease-in-out justify-evenly gap-2 md:hidden
            ${menuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}
            ${darkMode ? 'dark bg-slate-950 navvy-shad-none' : 'bg-slate-50'}`}>
                <li className={`transition-all duration-500 ease-out transform ${menuOpen ? 'opacity-100 translate-y-0 delay-100' : 'opacity-0 translate-y-10'}`}>
                    <NavLink to="/home" className='moby-nav text-slate-950 dark:text-white' onClick={linkExit}>About</NavLink>
                </li>
                <li className={`transition-all duration-500 ease-out transform ${menuOpen ? 'opacity-100 translate-y-0 delay-200' : 'opacity-0 translate-y-10'}`}>
                    <NavLink to="/dev-portfolio" className='moby-nav text-slate-950 dark:text-white' onClick={linkExit}>Coding Portfolio</NavLink>
                </li>
                <li className={`transition-all duration-500 ease-out transform ${menuOpen ? 'opacity-100 translate-y-0 delay-300' : 'opacity-0 translate-y-10'}`}>
                    <NavLink to="/design-portfolio" className='moby-nav text-slate-950 dark:text-white' onClick={linkExit}>Design Portfolio</NavLink>
                </li>
            </ul>



            <ul className='flex items-center transition-width justify-evenly gap-2 max-md:hidden transition-all duration-300'>
            <li className=''>
                <NavLink to="/home" className='navvy-btn flex justify-center align-middle text-sm sm:text-md md:text-md lg:text-md xl:text-lg 2xl:text-lg font-semibold text-slate-900 transition-all duration-300 hover:text-slate-950 py-2 rounded-md dark:text-slate-50 dark:hover:text-white' onClick={linkExit}>About</NavLink>
            </li>
            <li className=''>
                <NavLink to="/dev-portfolio" className='navvy-btn flex justify-center align-middle text-sm sm:text-md md:text-md lg:text-md xl:text-lg 2xl:text-lg font-semibold text-slate-900 transition-all duration-300 hover:text-slate-950 py-2 rounded-md dark:text-slate-50 dark:hover:text-white' onClick={linkExit}>Coding Portfolio</NavLink>
            </li>
            <li className=''>
                <NavLink to="/design-portfolio" className='navvy-btn flex justify-center align-middle text-sm sm:text-md md:text-md lg:text-md xl:text-lg 2xl:text-lg font-semibold text-slate-900 transition-all duration-300 hover:text-slate-950 py-2 rounded-md dark:text-slate-50 dark:hover:text-white' onClick={linkExit}>Design Portfolio</NavLink>
            </li>

            </ul>


        </nav>    
    </>
  )
}

export default NavBar