import React from 'react';
import Social from '../components/Social';
import BioPage from './bio-page';
import { useTheme } from '../ThemeContext';
// import transition from '../Transitions';

const Home = () => {

  /*
  
  Breakpoint prefix	Minimum width	CSS
    sm	640px	@media (min-width: 640px) { ... }
    md	768px	@media (min-width: 768px) { ... }
    lg	1024px	@media (min-width: 1024px) { ... }
    xl	1280px	@media (min-width: 1280px) { ... }
    2xl	1536px	@media (min-width: 1536px) { ... }

  */

    const { darkMode } = useTheme();

  return (
    <div className={`flex flex-col justify-center overflow-x-hidden ${darkMode ? 'dark bg-slate-950' : 'rainbow'}`}>     
      <main className='w-full flex-col justify-items-center z-0 transition-all duration-300 lg:pt-10'>
        
        <BioPage /> {/* Pass props using context */}

        <footer className='pt-5 mt-10 flex pb-12 w-full justify-center'>
          <Social />
        </footer>

      </main>
    </div>
  );
};

export default Home;
