import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTimes, FaArrowLeft, FaArrowRight } from 'react-icons/fa';

// const importAll = (requireContext) => requireContext.keys().map(requireContext);
// const images = importAll(require.context('../media/Artwork/Logos', false, /\.(png|jpe?g|svg)$/));

const imgs = [
  {title: 'Ivy\'s Tribe', alt: 'Ivy\'s Tribe', desc: 'This logo was a special project', src: '../Artwork/Logos/Ivys-Tribe-01.png',},
  {title: 'Pelican Pays', alt: 'Pelican Pays', desc: '', src: '../Artwork/Logos/Pelican-Pays.png',},
  {title: 'Pelican State of Mind', alt: 'Pelican State of Mind', desc: '', src: '../Artwork/Logos/Pelican-State-of-Mind.png',},
  {title: 'SOAR Foundation', alt: 'SOAR Foundation', desc: '', src: '../Artwork/Logos/SOAR-Foundation.png',},
  {title: 'Walker OMV Express', alt: 'Walker OMV Express', desc: '', src: '../Artwork/Logos/Walker-OMV-Express.png',},
  {title: 'Financial Wellness Program', alt: 'Financial Wellness Program', desc: '', src: '../Artwork/Logos/Financial-Wellness-Branding.jpg',},
  {title: 'The Book Haul', alt: 'The Book Haul', desc: '', src: '../Artwork/Logos/The-Book-Haul.jpg',},
  // {title: 'Ignite Event Logo', alt: 'Ignite Event Logo', desc: '', src: '../Artwork/Logos/Ignite-Event.jpg',}
];

const LogoGallery = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const closeModal = () => setSelectedIndex(null);

  const nextImage = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % imgs.length);
  };

  const prevImage = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex === 0 ? imgs.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="p-5 mt-5">
      <div>
      <h3 className='text-slate-950 text-3xl font-bold py-1 md:text-4xl dark:text-slate-400'>Brand Design</h3>
      <p className='text-left text-md my-5 py-1 sm:leading-8 text-slate-950 md:leading-8 md:text-xl dark:text-slate-400'>For the duration of my design career I've also had the pleasure of working on branding campaigns and logos for events, social media, and small businesses. These are examples of the most recent brand projects I've worked on.</p>
      </div>
      <div className="flex flex-1 flex-col gap-10 sm:flex-row flex-wrap">
        {imgs.map((image, index) => {
          // const capText = image.split('/').pop().split('.')[0].replace(/-/g, ' ');

          return (
            <div
              key={index}
              className="three-col rounded-lg p-6 border-0 border-slate-50 bg-slate-50 hover:bg-white hover:dark:bg-slate-800 dark:bg-slate-900 cursor-pointer"
              onClick={() => setSelectedIndex(index)}
            >
              <div className="flex flex-wrap flex-col h-auto items-center justify-center">
              <h2 className="w-full pb-6 font-bold text-slate-950 dark:text-white ">
                  {image.title}
                </h2>
              <img
                  src={image.src}
                  alt={`Portfolio Item ${image.alt}`}
                  className="rounded-sm overflow-hidden w-full h-auto object-cover"
                />

              </div>
            </div>
          );
        })}
      </div>

      {/* Modal */}
      <AnimatePresence>
        {selectedIndex !== null && (
          <>
            {/* Overlay */}
            <motion.div
              className="fixed inset-0 bg-black bg-opacity-50 z-[1000]" // High z-index
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={closeModal}
            />

            {/* Modal Content */}
            <motion.div
              className="fixed inset-0 flex items-center justify-center z-[1100]" // Higher z-index than the overlay
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
            >
              <div className="bg-white dark:bg-slate-900 rounded-lg max-w-3xl w-full shadow-lg h-[90vh] flex flex-col items-center justify-center relative">
              {/* Close Button */}
                <button
                  className="absolute top-4 right-4 text-slate-950 dark:text-slate-400 text-xl"
                  onClick={closeModal}
                >
                  <FaTimes />
                </button>

                {/* Navigation Arrows */}
                <button
                  className="absolute m-10 left-4 top-1/2 transform -translate-y-1/2 text-slate-950 dark:text-slate-400 text-2xl"
                  onClick={prevImage}
                >
                  <FaArrowLeft />
                </button>
                <button
                  className="absolute m-10 right-4 top-1/2 transform -translate-y-1/2 text-slate-950 dark:text-slate-400 text-2xl"
                  onClick={nextImage}
                >
                  <FaArrowRight />
                </button>

                {/* Image and Caption */}
                <img
                  src={imgs[selectedIndex].src}
                  alt={`Logo for ${imgs[selectedIndex].alt}`}
                  className="p-6 mt-10 justify-self-center rounded-lg overflow-hidden w-auto h-auto max-h-[80vh]"
                  onClick={nextImage}
                />
                <p className="text-center mt-2 mb-6 text-slate-950 dark:text-slate-400">
                  {imgs[selectedIndex].title}
                </p>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LogoGallery;
