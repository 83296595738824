import React from 'react';
import { FaTools } from 'react-icons/fa'

const videos = [
      {
        url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Motion-Graphics-Reel.mp4",
        tools: "Blender, Illustrator, After Effects, Photoshop, Premiere Pro",
        description: "Selected motion graphic projects I've worked on recently. Music sourced from Envato.",
        caption: "Motion Graphics Reel",
        mode: "landscape",
        poster: "../Artwork/reel-poster.jpg"
    },

    {
        url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Grant-Winners.mp4",
        caption: "Instagram Reel",
        tools: "Blender, After Effects",
        description: "This projected involved modeling and animating a looping 3D motion graphic backdrop with a feature card highlighting the winners in the foreground. Audio was sourced from Envato.",
        mode: "portrait",
        poster: "../Artwork/pelican-pays-poster.jpg"
    },
    {
        url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Giveaway-Reel.mp4",
        tools: "Blender, After Effects",
        description: "3D Modeled and animated 12 reels for a social media giveaway campaign. This is a reel from the campaign. Audio was sourced from Envato.",
        caption: "Instagram Reel",
        mode: "portrait",
        poster: "../Artwork/giveaway-poster.jpg"
    },
    {
        url: "https://d234mlpnnu9c2c.cloudfront.net/portfolio/Gold-Standard-Insta.mp4",
        tools: "Blender, After Effects, Photoshop",
        description: "Animated 3D model and physics simulation to create visual interest highlighting the 'gold standard' of rates campaign message. The animation was used for reels and static images were created for Google Display Ads. Audio was sourced from Envato.",
        caption: "Story Ad",
        mode: "portrait",
        poster: "../Artwork/deposit-poster.jpg"
    }

];

const VideoGallery = () => {

  return (
    <div className="p-5 mt-5">
      <div>
        <h3 className="text-pearl-950 text-3xl font-bold py-1 md:text-4xl dark:text-pearl-500">Motion Graphics</h3>
        <p className="text-left text-md my-5 py-1 sm:leading-8 text-pearl-950 md:leading-8 md:text-xl dark:text-pearl-500">
          These are selected videos from recent motion graphic and video editing projects I've worked or collaborated on.
        </p>
      </div>
      <div className="flex flex-col flex-1 gap-10 py-10 justify-between lg:flex-row lg:flex-wrap">
        {videos.map((video, index) => {
          const vidMode = video.mode === "portrait";

          return (
            <div key={index} className={`rounded-lg p-6 border-pearl-50 bg-pearl-50 dark:bg-slate-900 ${ vidMode ? "basis-[43%] md:basis-[29%] lg:basis-[29%] xl:basis-[30%]": "w-full"}`} >
              <div className="flex flex-wrap flex-col-reverse h-auto items-center justify-center">
                <div className='flex flex-col w-full justify-start pt-6 min-h-60'>
                  <h3 className="w-full pb-2 text-xl font-bold text-pearl-950 dark:text-white ">
                    {video.caption}
                  </h3>
                  <p className='dark:text-white flex-row flex text-sm pb-4'>{video.description}</p>
                  <p className='dark:text-white flex-row font-bold text-xs flex'><FaTools className='mr-2 translate-y-1'/> Tools: {video.tools}</p>
                </div>
                <video controls autoPlay={false} muted={true} loop={false} className="rounded-sm overflow-hidden w-full h-auto object-cover" style={{ objectFit: 'contain', width: '100%', height: '100%' }} src={video.url} 
                poster={video.poster}
                type="video/mp4">
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VideoGallery;
