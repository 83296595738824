import React from 'react';
import { CaseStudies } from '../constants/constants.js';

const CaseStudiesComponent = () => {
  return (
    <div className='pb-2 mt-5'>
    
        <div className='min-h-max w-full mb-5 pb-2'>

            {CaseStudies.map((study, index) => (
                <div key={index} className='p-5 mb-10 rounded-lg shadow-lg md:p-10 bg-slate-100 dark:bg-slate-800'>

                <h2 className='text-2xl mb-4 font-bold md:text-4xl md:mb-10 text-slate-950 dark:text-slate-50'>{study.title}</h2>

                <p className='mt-2 text-lg md:text-2xl md:pb-6 text-slate-950 dark:text-slate-400'>
                    <strong className='dark:text-slate-50'>Objective:</strong> {study.overview.objective}
                </p>
                <p className='mt-1  pb-5 text-lg md:text-2xl md:pb-8 text-slate-950 dark:text-slate-400'>
                    <strong className='dark:text-slate-50'>Scope:</strong> {study.overview.scope}
                </p>

                <div className='mt-5 pb-2'>
                    <h3 className='text-lg pb-2 font-semibold text-slate-950 dark:text-slate-50'>Challenges</h3>
                    <ul className='list-disc ml-5 list-inside text-slate-950 dark:text-slate-400'>
                    {study.challenges.map((challenge, i) => (
                        <li key={i} className='pb-2'>{challenge}</li>
                    ))}
                    </ul>
                </div>

                <div className='mt-5 pb-2'>
                    <h3 className='text-lg pb-2 font-semibold text-slate-950 dark:text-slate-50'>Solution</h3>
                    <ul className='list-disc ml-5 list-inside text-slate-950 dark:text-slate-400'>
                    {study.solution.steps.map((step, i) => (
                        <li key={i} className='pb-2'>{step}</li>
                    ))}
                    </ul>
                </div>

                <div className='mt-5 pb-5'>
                    <h3 className='text-lg pb-2 font-semibold text-slate-950 dark:text-slate-50'>Implementation</h3>
                    <ul className='list-disc ml-5 list-inside text-slate-950 dark:text-slate-400'>
                    {study.implementation.process.map((step, i) => (
                        <li key={i} className='pb-2'>{step}</li>
                    ))}
                    </ul>
                </div>

                <div className='my-5 py-5 px-6 border-2 rounded-lg w-full bg-gray-100 dark:bg-slate-950 shadow-lg'>
                    <h3 className='text-lg pb-2 font-semibold text-slate-950 dark:text-slate-50'>Outcome</h3>
                    <ul className='ml-5 list-disc list-inside text-slate-950 dark:text-slate-200'>
                        {study.outcome.improvements.map((improvement, i) => (
                        <li key={i} className='pb-2'>{improvement}</li>
                        ))}
                    </ul>
                    {/* <p className='mt-2 text-slate-950 dark:text-slate-400'>
                        <strong>Feedback:</strong> {study.outcome.feedback}
                    </p>
                    <p className='text-slate-950 dark:text-slate-400'>
                        <strong>Impact:</strong> {study.outcome.impact}
                    </p> */}
                    <hr className='my-5'/>
                    <h3 className='text-lg pb-2 font-semibold text-slate-950 dark:text-slate-50'>Takeaways</h3>
                    <ul className='list-disc pb-3 ml-5 pr-3 list-inside text-slate-950 dark:text-slate-200'>
                    {study.takeaways.map((takeaway, i) => (
                        <li key={i} className='pb-2'>{takeaway}</li>
                    ))}
                    </ul>
                </div>

                    <div className='mt-[3rem]'>
                        <h2 className='text-2xl mb-4 font-bold text-slate-950 dark:text-slate-50'>Media</h2>

                        <div className='port-gallery grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4'>
                        {study.media.map((item, i) => {
                            if (item.type === 'image') {
                            return (
                                //  shadow-sm p-6 rounded-xl basis-1/2 bg-slate-50 dark:bg-slate-900
                                <div className='port-item border-2 my-4 rounded-lg overflow-hidden w-full min-h-max md:max-w-full bg-slate-200 dark:bg-slate-900 dark:border-slate-700'>
                                    <div className='p-5 relative top-0 left-0 h-auto border-b-0'>
                                        <h4 className='text-left font-semibold pb-2 dark:text-slate-50'>{item.alt}</h4>
                                    </div>
                                    <img
                                    key={i}
                                    src={item.url}
                                    alt={item.alt}
                                    className='rounded-md overflow-hidden'
                                    />                                
                                </div>
                            );
                            } else if (item.type === 'video') {
                            return (
                                <div className='port-item border-2 my-4 rounded-lg overflow-hidden w-full min-h-max md:max-w-full bg-slate-200 dark:bg-slate-900 dark:border-slate-700'>
                                    <div className='p-5 relative top-0 left-0 h-auto border-b-0'>
                                        <h4 className='text-left font-semibold pb-2 dark:text-slate-50'>{item.alt}</h4>
                                    </div>
                                    <video
                                        key={i}
                                        controls
                                        poster={item.poster}
                                        className='rounded-md overflow-hidden'
                                        style={{ objectFit: 'contain', width: '100%', maxHeight: '60vh' }}
                                    >
                                    <source src={item.url} type='video/mp4' />
                                    Your browser does not support the video tag.
                                    </video>
                                </div>
                            );
                            }
                            return null;
                        })}
                        </div>

                    </div>
                </div>
            ))}


        </div>
    </div>


  );
};

export default CaseStudiesComponent;
